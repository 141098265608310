import * as Sentry from '@sentry/browser';
import { sentryErrorIntegration } from '@ifixit/sentry';

const ERROR_SAMPLE_RATE_PRODUCTION = 0.05;
const ERROR_SAMPLE_RATE_DEV = 0;

const TRACE_SAMPLE_RATE = App.isProduction ? 0.005 : 0;

const errorSampleRate = App.isProduction ? ERROR_SAMPLE_RATE_PRODUCTION : ERROR_SAMPLE_RATE_DEV;

let errorsLeftToSend = 1;
const forceSentry = App.canIForceSentryReporting && /(forceSentry)/.test(window.location.search);
const performanceOptions = App.sentryPerformance
   ? {
        integrations: [
           Sentry.browserTracingIntegration(),
           sentryErrorIntegration(),
           Sentry.thirdPartyErrorFilterIntegration({
              filterKeys: ['ifixitFirstParty'],
              behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
           }),
        ],
        tracesSampleRate: TRACE_SAMPLE_RATE,
     }
   : {
        integrations: [
           sentryErrorIntegration(),
           Sentry.thirdPartyErrorFilterIntegration({
              filterKeys: ['ifixitFirstParty'],
              behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
           }),
        ],
     };

let sampleRate = forceSentry ? 1 : errorSampleRate;
let shouldSendToSentry = Math.random() < sampleRate;

Sentry.init({
   dsn: App.SENTRY_DSN_JS,
   environment: App.isProduction ? 'production' : 'dev',
   release: App.sentryRelease,
   ignoreErrors: ['TypeError: Network request failed'],
   ...performanceOptions,

   // We effect our sampling in beforeSend, so we tell sentry to sample all
   // errors
   sampleRate: 1,
   beforeSend: function (event) {
      if (shouldIgnoreUserAgent()) {
         return null;
      }

      if (!shouldSendToSentry) {
         return null;
      }

      if (errorsLeftToSend <= 0) {
         return null;
      }
      errorsLeftToSend--;
      return event;
   },
   normalizeDepth: 10,
});

Sentry.setTags(App.sentryTags);
Sentry.setUser({ ip_address: '{{auto}}' });

function shouldIgnoreUserAgent() {
   // Yeti is a web crawler / automation tool that seems to fake all api
   // responses and thus generates bunches of JS errors.
   return window.navigator.userAgent.match(/yeti/i);
}
